// Load Bootstrap JS
import bootstrap from 'bootstrap'
// Load Styles
import '../scss/main.scss';

import ScrollReveal from 'scrollreveal'

ScrollReveal().reveal('.reveal.top', {
  duration: 1500,
  origin: 'top', 
  reset: true
});
ScrollReveal().reveal('.reveal.bottom', {
  duration: 1500,
  origin: 'bottom', 
  reset: true
});

// App code
var hamburgerOpen = false;
const Hamburger = document.querySelector('#hamburger');
const OffcanvasMenu = document.querySelector('#offcanvasMenu');
const Navbar = document.querySelector('#navbar');

OffcanvasMenu.addEventListener('hide.bs.offcanvas', function () {
    Hamburger.classList.remove('is-active');
    hamburgerOpen = false;
});
OffcanvasMenu.addEventListener('show.bs.offcanvas', function () {
    Hamburger.classList.add('is-active');
    hamburgerOpen = true;
});

// console.log(`Hello ${process.env.VAR1}`);
